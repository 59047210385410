import {} from "@fortawesome/free-solid-svg-icons";
import { useTranslations } from "next-intl";
import React from "react";
import Image from "next/image";
import agDesktop from "../../public/images/ag-desktop.png";
import DownloadSection from "./DownloadSection";
import { isOnboardingModalOpenedVar } from "../../graphql/cache";
import Button from "../button/Button";

export default function Downloads() {
  const translate = useTranslations();

  const openOnboarding = () => {
    isOnboardingModalOpenedVar({
      isOpened: true,
      title: translate("features") as string,
    });
  };

  return (
    <div className="pl-5 mt-24 pb-16">
      <div className="text-2xl font-semibold">{translate("downloadTitle")}</div>
      <div className="mt-2 text-gray-500 dark:text-gray-400">
        {translate("downloadDesc")}{" "}
      </div>

      <div className="flex flex-col">
        <div className="mt-14 flex flex-row items-center">
          <div className="w-1/2 mr-20">
            <div className="w-full">
              <Image
                src={agDesktop}
                alt="ag-desktop"
                className="rounded-lg"
                priority
                width={2593}
                height={1652}
                placeholder="blur"
              />
            </div>
            <Button
              title={translate("exporeAppFeatures")}
              onClick={openOnboarding}
              className="mt-3"
              size="lg"
            />
          </div>
          <DownloadSection />
        </div>
      </div>
    </div>
  );
}
