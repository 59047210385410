import { GetStaticProps } from "next";
import { useTranslations } from "next-intl"; // NOSONAR
import { useEffect } from "react";
import ArticlesCarousel from "../components/articlesCarousel/ArticlesCarousel";
import Layout from "../components/common/Layout";
import Container from "../components/container/Container";
import Downloads from "../components/downloads/Downloads";
import JoinedEstimateRooms from "../components/home/joinedEstimateRooms/JoinedEstimateRooms";
import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_ONBOARDING_KEY,
} from "../constants/common";
import { isOnboardingModalOpenedVar } from "../graphql/cache";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { getSortedPostsData } from "../lib/articles";
import { Article } from "../types/common";

export default function Home({ articlesData }) {
  const translate = useTranslations();
  const [isOnboarded, setIsOnboarded] = useLocalStorage(
    LOCAL_STORAGE_ONBOARDING_KEY,
    false
  );

  const [currentUserAccessToken] = useLocalStorage(
    LOCAL_STORAGE_ACCESS_TOKEN,
    undefined
  );

  useEffect(() => {
    if (currentUserAccessToken && !isOnboarded) {
      setTimeout(() => {
        isOnboardingModalOpenedVar({
          isOpened: true,
          title: translate("onboarding") as string,
        });
        setIsOnboarded(true);
      }, 500);
    }
  }, [isOnboarded, currentUserAccessToken]);

  return (
    <Layout pageTitle={translate("appName") as string}>
      <Container>
        <div className="mt-16">
          <JoinedEstimateRooms />
        </div>
        <Downloads />
        <ArticlesCarousel articlesData={articlesData} />
      </Container>
    </Layout>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const articlesData: Article[] = getSortedPostsData();

  return {
    props: {
      articlesData,
      messages: {
        ...require(`../translations/${locale}.json`),
      },
    },
  };
};
