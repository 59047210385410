import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useTranslations } from "next-intl";
import Link from "next/link";
import React from "react";
import { ARTICLE_ITEM_WIDTH } from "../../constants/common";
import { Article } from "../../types/common";
import ArticleItem from "../articleItem/ArticleItem";

interface ArticlesCarouselProps {
  articlesData: Article[];
}

export default function ArticlesCarousel({
  articlesData,
}: ArticlesCarouselProps) {
  const translate = useTranslations();

  return (
    <div className="pl-5 mt-6 pb-32">
      <div className="text-2xl font-semibold">{translate("agileArticles")}</div>
      <div className="mt-2 text-gray-500 dark:text-gray-400 mb-3">
        {translate("agileArticlesDesc")}{" "}
        <Link href="/articles">
          <a className="text-main-blue underline">{translate("readMore")}</a>
        </Link>
        .
      </div>
      <Splide
        options={{
          autoplay: true,
          height: "30rem",
          perPage: 3,
          perMove: 1,
          gap: "3rem",
          pagination: false,
        }}
      >
        {articlesData.map((article) => {
          const {
            id,
            title,
            date,
            author,
            tags,
            thumbnail,
            authorAvatar,
            sourceName,
            sourceURL,
          } = article;
          return (
            <SplideSlide key={`article-${id}`}>
              <ArticleItem
                id={id}
                title={title}
                date={date}
                author={author}
                tags={tags}
                thumbnail={thumbnail}
                key={id}
                authorAvatar={authorAvatar}
                sourceName={sourceName}
                sourceURL={sourceURL}
              />
            </SplideSlide>
          );
        })}
      </Splide>
    </div>
  );
}
