import React, { memo, useState } from "react";
import { Article } from "../../types/common";
import DateFormat from "../dateFormat/DateFormat";
import Image from "next/image";
import Link from "next/link";
import { ARTICLE_ITEM_WIDTH } from "../../constants/common";

interface ArticleItemProps extends Article {}

function ArticleItem({
  id,
  title,
  date,
  author,
  tags,
  thumbnail,
  authorAvatar,
}: ArticleItemProps) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Link href={`/articles/${id}`}>
      <a
        className={`mx-5 mb-24 hover:no-underline cursor-pointer ${
          isHovered ? "drop-shadow-lg" : ""
        }`}
        style={{ width: ARTICLE_ITEM_WIDTH }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img
          width={ARTICLE_ITEM_WIDTH}
          height={210}
          alt="illustration"
          src={thumbnail}
          className={`rounded-lg transition-shadow ${
            isHovered ? "shadow-lg shadow-teal-400 dark:shadow-teal-700" : ""
          }`}
        />
        <div className="flex flex-row items-center justify-between mt-6">
          <div className="flex flex-row items-center">
            <div className="px-5 py-1 bg-main-blue flex flex-row items-center rounded-2xl">
              <div className="w-3 h-3 rounded-full bg-teal-100" />
              <span className="text-white ml-2 text-sm font-medium">
                {tags[0].toUpperCase()}
              </span>
            </div>
          </div>
          <div className="text-dark-blue dark:text-white">
            <DateFormat dateString={date} />
          </div>
        </div>
        <div
          className={`mt-5 text-dark-blue dark:text-white text-2xl font-bold ${
            isHovered ? "underline" : ""
          }`}
        >
          {title}
        </div>
        <div className="flex flex-row items-center mt-5">
          <Image
            src={authorAvatar}
            alt={author}
            width="26"
            height="26"
            className="rounded-full"
          />
          <div className="ml-3 text-sm text-dark-blue dark:text-white">
            {author}
          </div>
        </div>
      </a>
    </Link>
  );
}

export default memo(ArticleItem);
